/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import avatar from "./avatar.jpg"
import Debug from "../Debug"

const Bio = () => {


  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  // const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bio" style={{marginTop: "40px", display: "flex", alignItems: "center"}}>
      <div
      style={{
        flexShrink: 0,
      }}>

        <img
          src={avatar}
          alt={author?.name || ``}
          style={{
            borderRadius: `50%`,
            width: "100px"
          }}
        />
      </div>

      {/* <Debug data={{...data}} /> */}

      <div style={{flexGrow: 1, padding: "7px"}}>
        <p>
          <strong>Lekarz Ernest Dróżdż </strong>
          - pasjonat profilaktyki zdrowia oraz harmonijnego stylu życia. Na codzień pracuje w zielonogórskich przychodniach.
          {/* {` `}
          <a href={`https://twitter.com/${social?.twitter || ``}`}>
            You should follow them on Twitter
          </a> */}
        </p>
      </div>


    </div>
  )
}

export default Bio


// avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
//   childImageSharp {
//     fixed(width: 50, height: 50, quality: 95) {
//       ...GatsbyImageSharpFixed
//     }
//   }
// }
import React from "react"
import { Link, graphql } from "gatsby"

// import SEO from "../components/Seo"
import Template2cNew from "../components/Template2cNew"
// import Img from "gatsby-image"

import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"




// import ButtonBackToTop from "../components/ButtonBackToTop"
// import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Box, IconButton, Button, Grid, Stack, Typography } from '@mui/material';
// import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import Bio from "../components/Bio"
import Debug from "../components/Debug"
import Markdown from "../components/Markdown"
import Layout from "../components/Layout";

const BlogPostTemplate = ({ data }) => {

  const {
    markdownRemark: {
      html,
      rawMarkdownBody,
      frontmatter: {
        overline,
        title,
        excerpt,
        description,
        featuredimage
      }
    },
    ...rest
  } = data


  const image = getImage(featuredimage)




  return (
    <Layout>

      <div id="article">




        {/* <Debug data={{ rawMarkdownBody }} /> */}
        {/* <SEO
        title={title}
      // description={post.frontmatter.description || post.excerpt}
      /> */}
        {/* <ButtonBackToTop /> */}

        <Box mx="auto" style={{
          maxWidth: "700px",
          padding: "10px"
        }}>

          <Link to="/">
            <Box>

              <Button
                startIcon={<KeyboardBackspaceRoundedIcon />}
              >
                powrót
              </Button>
            </Box>

          </Link>



          <Grid container alignItems={"center"}>
            <Grid item xs={6}>
              <GatsbyImage image={image} alt="featured image" />

            </Grid>

            <Grid item xs={6}>
            <Typography variant="caption">
                  {overline}
                </Typography>
              <Stack
                className="content"

              >
                {/* <p>{overline}</p> */}

                {/* <Typography variant="h1">
                  {title}
                </Typography> */}

              
                <h1>{title}</h1>
                <p>{description}</p>
              </Stack>
            </Grid>
          </Grid>



        </Box>


        {/* zonk */}
        {/* {html} */}

        <Box my={5}>
          <Container maxWidth="sm">
            {/* <article
            className="text-long"
            itemScope
            itemType="http://schema.org/Article"
          > */}
            {/* <MDXRenderer embeddedImages={embeddedImagesByKey}>{body}</MDXRenderer> */}


            <div className="content"
            // this class name is crucial for styling
            >
              <div dangerouslySetInnerHTML={{ __html: html }} />

            </div>
            {/* <Markdown>

              {rawMarkdownBody}
            </Markdown> */}
            {/* </article> */}

            <Bio />

          </Container>


        </Box>

      </div>
    </Layout>

  )
}

export default BlogPostTemplate



export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        overline
        featuredimage {
          childImageSharp {
            gatsbyImageData(
            width: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }}
      }
    }
  }
`;



// export const pageQuery = graphql`
//   query BlogPostBySlug($slug: String!) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//             id
//       body
//       frontmatter {
//         overline
//         title
//         excerpt
//         featured {
//           name
//           childImageSharp {
//             original {
//               width
//               height
//               src
//             }
//             fluid(quality: 90) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         images {
//           name
//           childImageSharp {
//             original {
//               width
//               height
//               src
//             }
//             fluid(quality: 90) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }


//       }
//     }
//   }
// `

import React, { useState } from "react";
// import ReactMarkdown from "react-markdown";
import {Typography, ListItemText} from '@mui/material';






const MyImage = props => {
    const [fullSize, setFullSize] = useState();
    const handleClick = () => {
        setFullSize(!fullSize);
    };
    return (
        <img
            style={{ width: fullSize ? "400px" : "200px" }}
            alt={props.alt}
            src={props.src}
            onClick={handleClick}
        />
    );
};


function headingRenderer(props) {

    switch (props.level) {
        case 1: return <Typography variant={`h${props.level}`}>{props.children}</Typography>;
        case 2: return <Typography variant={`h${props.level}`} color="secondary">{props.children}</Typography>;
        case 3: return <Typography variant={`h${props.level}`}>{props.children}</Typography>;
        case 4: return <Typography variant={`h${props.level}`} color="secondary">{props.children}</Typography>;
        case 5: return <Typography variant={`h${props.level}`}>{props.children}</Typography>;
        default: return null;
    }


}

const ParagraphRenderer = (props) => <Typography variant="body1">{props.children}</Typography>

const ListItemRenderer = (props) => <li><ListItemText primary={props.children} /></li>





const renderers = {
    // image: MyImage,
    heading: headingRenderer,
    paragraph: ParagraphRenderer,
    listItem: ListItemRenderer
};

// export default ({ children }) => <ReactMarkdown components={renderers}>{children}</ReactMarkdown>
export default ({ children }) => children

